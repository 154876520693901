/* eslint-disable */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserLocalPersistence, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';



const firebaseConfig = {
  apiKey: "AIzaSyAYHRoz3hfjWvghUAGenW_dg_T0SgbWgBE",
  authDomain: "life-grid-6ebe7.firebaseapp.com",
  projectId: "life-grid-6ebe7",
  storageBucket: "life-grid-6ebe7.appspot.com",
  messagingSenderId: "307797188890",
  appId: "1:307797188890:web:b51ff2afcf0308dd298ca3",
  measurementId: "G-MS995W0E0R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();


// Get a reference to the auth service
const auth = getAuth(app);

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistence enabled
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

const db = getFirestore(app);

export { app, auth, db, googleProvider, signInWithPopup };