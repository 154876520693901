<template>
  <div class="carousel-container">
    <div class="carousel-wrapper">
      <div class="carousel-slide" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
        <img v-for="(image, index) in images" 
             :key="index" 
             :src="image" 
             :alt="`Screenshot ${index + 1}`"
             class="carousel-image"
             @click="openFullscreen(index)">
      </div>
      
      <button class="carousel-button prev" @click="previousSlide" :disabled="isTransitioning">
        <v-icon>mdi-chevron-left</v-icon>
      </button>
      
      <button class="carousel-button next" @click="nextSlide" :disabled="isTransitioning">
        <v-icon>mdi-chevron-right</v-icon>
      </button>
    </div>

    <!-- Fullscreen Modal -->
    <div v-if="isFullscreen" class="fullscreen-modal" @click="closeFullscreen">
      <div class="fullscreen-content" @click.stop>
        <img :src="images[fullscreenIndex]" :alt="`Screenshot ${fullscreenIndex + 1}`" class="fullscreen-image">
        
        <button class="fullscreen-button prev" @click="previousFullscreen">
          <v-icon>mdi-chevron-left</v-icon>
        </button>
        
        <button class="fullscreen-button next" @click="nextFullscreen">
          <v-icon>mdi-chevron-right</v-icon>
        </button>

        <button class="close-button" @click="closeFullscreen">
          <v-icon>mdi-close</v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Import images at the top
import image1 from '@/assets/1.png'
import image2 from '@/assets/2.png'
import image3 from '@/assets/3.png'

export default {
  name: 'ImageCarousel',
  data() {
    return {
      currentIndex: 0,
      isTransitioning: false,
      isFullscreen: false,
      fullscreenIndex: 0,
      images: [
        image1,
        image2,
        image3
      ]
    }
  },
  methods: {
    previousSlide() {
      if (this.isTransitioning) return;
      this.isTransitioning = true;
      this.currentIndex = this.currentIndex === 0 ? this.images.length - 1 : this.currentIndex - 1;
      setTimeout(() => {
        this.isTransitioning = false;
      }, 300);
    },
    nextSlide() {
      if (this.isTransitioning) return;
      this.isTransitioning = true;
      this.currentIndex = this.currentIndex === this.images.length - 1 ? 0 : this.currentIndex + 1;
      setTimeout(() => {
        this.isTransitioning = false;
      }, 300);
    },
    openFullscreen(index) {
      this.fullscreenIndex = index;
      this.isFullscreen = true;
      document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
    },
    closeFullscreen() {
      this.isFullscreen = false;
      document.body.style.overflow = ''; // Restore scrolling
    },
    previousFullscreen() {
      this.fullscreenIndex = this.fullscreenIndex === 0 ? this.images.length - 1 : this.fullscreenIndex - 1;
    },
    nextFullscreen() {
      this.fullscreenIndex = this.fullscreenIndex === this.images.length - 1 ? 0 : this.fullscreenIndex + 1;
    }
  }
}
</script>

<style scoped>
.carousel-container {
  width: 100%;
  max-width: 300px;
  margin: 50px auto 0px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 1px 10px 2px rgb(56 56 56 / 10%);
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carousel-image {
  min-width: 100%;
  height: 100%;
  cursor: pointer;
  transition: opacity 0.2s;
}

.carousel-image:hover {
  opacity: 0.9;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 2;
}

.carousel-button:hover {
  background: rgba(255, 255, 255, 0.9);
}

.carousel-button.prev {
  left: 20px;
}

.carousel-button.next {
  right: 20px;
}

.carousel-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Fullscreen Modal Styles */
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

.fullscreen-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
}

.fullscreen-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

.fullscreen-button.prev {
  left: 20px;
}

.fullscreen-button.next {
  right: 20px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.3);
}
</style>