<template>
  <v-main style="flex: unset !important;">
    <div class="demo-container">
      <v-btn @click="startDemo" class="demo-button" color="primary" v-if="!isDemoActive">
        Try Demo Version
      </v-btn>

      <v-dialog v-model="isDemoActive" fullscreen hide-overlay>
        <v-card style="padding: 0 !important">
          <v-toolbar height="32px" dark color="primary">
            <v-btn icon @click="isDemoActive = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- <v-toolbar-title>Demo Version</v-toolbar-title> -->
            <v-spacer></v-spacer>
            <div class="demo-notice">Demo Mode - Data will not be saved</div>
          </v-toolbar>

          <v-card-text>
            <div v-if="!hasStartedDemo" class="demo-setup">
              <h2>Welcome to Life in Weeks Demo</h2>
              <p>Enter your birthdate to start exploring:</p>
              <v-text-field
                v-model="birthdate"
                type="date"
                required
                style="justify-content: space-evenly !important;"
                class="demo-input"
              ></v-text-field>
              <v-btn @click="initializeDemo" color="primary">Start Demo</v-btn>
            </div>

            <div v-else class="life-view-container">

              <!-- Quote at the top -->
              <div v-if="quotePosition === 'top'" class="quote-container quote-top">
                <div v-if="showQuotes" class="random-quote">{{ randomQuote }}</div>
                <div v-if="showHoursUntilDeath" class="hours-until-80">
                  {{ hoursUntilDeath }}
                  <span class="hours-until-label">Hours to go</span>
                </div>
              </div>
              <!-- Quote on the side (existing implementation) -->
            <div v-if="quotePosition === 'side'" class="daily-quote">
              <div v-if="showQuotes" class="random-quote">{{ randomQuote }}</div>
              <div v-if="showHoursUntilDeath" class="hours-until-80"> {{ hoursUntilDeath }}<span class="hours-until-label">Hours to go</span></div>
            </div>

              <div class="life-grid" :class="{ 'compact-view': compactView }">
                <div v-if="hoveredWeek" class="month-year-overlay" ref="overlay">
                  {{ formatMonthYear(hoveredWeek.week) }} - Age: {{ formatAge(hoveredWeek.week.age) }}
                </div>
                <div
                  v-for="(year, yearIndex) in years"
                  :key="yearIndex"
                  :class="['year-row', { 'year-gap': showYearGaps && (yearIndex + 1) % 5 === 0 }]"
                >
                  <div
                    v-for="(week, weekIndex) in year"
                    :key="weekIndex"
                    :class="[
                      'square',
                      { 'past-week': week.isPast },
                      { 'current-week': week.isCurrent },
                      { 'has-milestone': hasMilestone(week.number) },
                      getLifeStageColor(week.age)
                    ]"
                    @click="zoomWeek(week)"
                    @mouseenter="showOverlay(week, $event)"
                    @mouseleave="hoveredWeek = null"
                  >
                    <div v-if="hasMilestone(week.number)" class="milestone-indicator"></div>
                  </div>
                </div>
              </div>

              <div v-if="showWeeklyTodos" 
                class="weekly-todos"
                :class="{ 'hidden-when-zoomed': isZoomedViewOpen }">
                <h4>This Week's Todos</h4>
                <div class="current-week-todo-progress-container">
                  <div class="todo-progress-bar" :style="{ width: currentWeekTodoProgress + '%' }"></div>
                </div>
                <div v-for="todo in currentWeekTodos" :key="todo.id" class="todo-item">
                  <v-checkbox v-model="todo.done" @change="updateTodoStatus(todo)" hide-details dense></v-checkbox>
                  <span :class="{ 'todo-done': todo.done }" class="todo-text">{{ todo.text }}</span>
                </div>
                <div class="todo-section2">
                  
                  <v-text-field 
                    density="compact" 
                    v-model="todoInput" 
                    label="Add To Do" 
                    placeholder="Enter a to-do item" 
                    variant="outlined" 
                    class="todo-input" 
                    @keyup.enter="addTodo"
                  ></v-text-field>
                  <v-btn class="add-todo-button weekly-summary-button" @click="addTodo">ADD</v-btn>
                </div>
              </div>

              <div v-if="filteredDailyHabits.length > 0" class="daily-habits">
                <h4>Daily Habits</h4>
                <div v-for="habit in filteredDailyHabits" :key="habit.id" class="habit-item">
                  <v-checkbox v-model="habit.completed" @change="updateHabitStatus(habit)" hide-details dense></v-checkbox>
                  <span :class="{ 'habit-done': habit.completed }" class="habit-text">{{ habit.name }}</span>
                </div>
              </div>

              <!-- Zoomed Week View -->
              <div v-if="zoomedWeek" class="zoomed-view-backdrop" @click.self="closeZoomedView">
                <div class="zoomed-view">
                  <div class="arrow-button left-arrow" @click="goToPreviousWeek">
                    &lt; {{ zoomedWeek.number - 1 }}
                  </div>
                  <div class="arrow-button right-arrow" @click="goToNextWeek">
                    {{ zoomedWeek.number + 1 }} &gt;
                  </div>
                  <div class="week-header">
                    <div class="week-number">
                      #{{ zoomedWeek.number }}
                      <div class="week-title">WEEK</div>
                    </div>
                    <div class="age">
                      {{ formatAge(zoomedWeek.age) }}
                      <div class="age-title">AGE</div>
                    </div>
                  </div>
                  <div class="week-details">
                    <p>{{ formatDate(zoomedWeek.startDate) }} - {{ formatDate(zoomedWeek.endDate) }}</p>
                  </div>
                  <div class="summary-section">
                    <div
                      v-if="!editingSummary"
                      class="summary-text"
                      :class="{ 'no-summary': !weekSummaries[zoomedWeek.number] }"
                      @click="startEditingSummary"
                    >
                      {{ weekSummaries[zoomedWeek.number] || 'Click to add a summary' }}
                    </div>
                    <v-textarea
                      v-else
                      v-model="currentSummary"
                      density="compact"
                      label="Summary"
                      variant="outlined"
                      @blur="saveSummary"
                    ></v-textarea>
                  </div>

                  <div v-if="weekGoals[zoomedWeek.number]?.length > 0" class="goal-list">
                    <h3>Milestones:</h3>
                    <div v-for="(goal, index) in weekGoals[zoomedWeek.number]" :key="index">
                      <v-icon class="delete-icon" @click="deleteMilestone(index)">mdi-close</v-icon>
                      {{ goal }}
                    </div>
                  </div>

                  <div class="goal-section">
                    <v-text-field
                      density="compact"
                      v-model="goalInput"
                      label="Milestones"
                      placeholder="Enter a milestone for this week"
                      variant="outlined"
                      @keyup.enter="saveGoal"
                    ></v-text-field>
                    <v-btn class="weekly-summary-button" @click="saveGoal">ADD</v-btn>
                  </div>

                  <!-- Add this new section for todos in the zoomed view -->
                  <div v-if="currentWeekTodos.length > 0" class="to-do-list">
                    <h3>To Do:</h3>
                    <div class="todo-progress-container">
                      <div class="todo-progress-bar" :style="{ width: currentWeekTodoProgress + '%' }"></div>
                    </div>
                    <div v-for="todo in currentWeekTodos" :key="todo.id" class="todo-item">
                      <div class="todo-content">
                        <v-icon class="delete-icon" @click="deleteTodo(todo)">mdi-close</v-icon>
                        <v-checkbox v-model="todo.done" @change="updateTodoStatus(todo)" hide-details dense></v-checkbox>
                        <span :class="{ 'todo-done': todo.done }" class="todo-text">{{ todo.text }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="todo-section">
                    <v-text-field 
                      density="compact" 
                      v-model="todoInput" 
                      label="Add To Do" 
                      placeholder="Enter a to-do item" 
                      variant="outlined" 
                      class="todo-input" 
                      @keyup.enter="addTodo"
                    ></v-text-field>
                    <v-btn class="add-todo-button weekly-summary-button" @click="addTodo">ADD</v-btn>
                  </div>

                  <div class="weeks-until-80">{{ weeksUntil80 }} weeks until you turn 80</div>
                </div>
              </div>

              <!-- Settings Icon -->
              <span class="settings-icon" @click="showSettingsDrawer = true">⚙️</span>

              <!-- Settings Drawer -->
              <v-dialog
                v-model="showSettingsDrawer"
                max-width="500"
                :position="{ top: '60px', right: '20px' }"
              >
                <v-card>
                  <v-card-title><h3>Settings</h3></v-card-title>
                  <v-divider class="my-3"></v-divider>
                  <v-card-text>
                    <v-switch class="settings-toggle" v-model="showYearGaps" label="Show 5 Year Gaps" :color="showYearGaps ? 'blue' : 'default'"></v-switch>
                  <v-switch class="settings-toggle" v-model="compactView" label="Compact View" :color="compactView ? 'blue' : 'default'"></v-switch>
                  <v-switch class="settings-toggle" v-model="showHoursUntilDeath" label="Show Hours To Go" :color="showHoursUntilDeath ? 'blue' : 'default'"></v-switch>
                  <div v-if="showHoursUntilDeath" class="decimal-places-setting">
                    <v-slider
                      v-model="hoursDecimalPlaces"
                      :min="0"
                      :max="10"
                      :step="1"
                      thumb-label
                      :label="`Decimal Places: ${hoursDecimalPlaces}`"
                      class="decimal-places-slider"
                    ></v-slider>
                  </div>
                  <v-switch class="settings-toggle" v-model="showWeeklyTodos" label="Show Weekly Todos" :color="showWeeklyTodos ? 'blue' : 'default'"></v-switch>
                  <v-switch class="settings-toggle" v-model="showQuotes" label="Show Quotes" :color="showQuotes ? 'blue' : 'default'"></v-switch>
                  <h3>Quote Position</h3>
                    <v-btn-toggle v-model="quotePosition" mandatory>
                      <v-btn value="top">Top</v-btn>
                      <v-btn value="side">Side</v-btn>
                      <v-btn value="bottom">Bottom</v-btn>
                    </v-btn-toggle>
                  <v-divider class="my-3"></v-divider>  
                  <div style="margin-bottom: 20px;">
                    <h3 style="margin-bottom: 20px;">Manage Daily Habits</h3>
                    <div style="display: flex; flex-direction: column; gap: 10px;">
                      <v-text-field
                        density="compact"
                        v-model="newHabitName"
                        label="New Habit"
                        @keyup.enter="addHabit"
                        placeholder="Enter a habit"
                        variant="outlined"
                        class="todo-input"
                      ></v-text-field>
                      
                      <div class="days-selector">
                        <div class="days-checkboxes">
                          <v-checkbox
                            v-for="day in ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']"
                            :key="day"
                            v-model="newHabitDays[day]"
                            :label="getDayLabel(day)"
                            density="compact"
                            hide-details
                            class="day-checkbox"
                          ></v-checkbox>
                        </div>
                      </div>

                      <div style="display: flex; margin: 0 auto">
                        <v-btn class="add-habit-button" @click="addHabit">ADD HABIT</v-btn>
                      </div>
                    </div>

                    <v-list class="habit-list">
                      <v-list-item v-for="habit in dailyHabits" :key="habit.id" class="habit-list-item">
                        <div class="habit-list-content">
                          <v-icon class="delete-icon" @click="deleteHabit(habit)">mdi-close</v-icon>
                          <span class="habit-name">{{ habit.name }}</span>
                          <div class="habit-days">
                            <span 
                              v-for="(dayLabel, index) in ['M', 'T', 'W', 'T', 'F', 'S', 'S']" 
                              :key="index"
                              :class="[
                                'day-indicator',
                                { 'day-active': habit.days && habit.days[getDayName(index)] }
                              ]"
                              @click="toggleHabitDay(habit, getDayName(index))"
                            >
                              {{ dayLabel }}
                            </span>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </div>
                  <v-divider class="my-3"></v-divider>
                    <v-card-text class="support-text">
                      Developed by Daniel Ionashku — 
                      <a href="https://buymeacoffee.com/ionashku" target="_blank">show support by buying me a coffee.</a> ☕️
                    </v-card-text>
                </v-card-text>
                </v-card>
              </v-dialog>
            </div>
            <!-- Quote at the bottom -->
            <div v-if="quotePosition === 'bottom'" class="quote-container quote-bottom">
              <div v-if="showQuotes" class="random-quote">{{ randomQuote }}</div>
              <div v-if="showHoursUntilDeath" class="hours-until-80"> <div style="display: flex; margin: 0 auto"> {{ hoursUntilDeath }}</div><span class="hours-until-label">Hours to go</span></div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-main>
</template>

<script>
export default {
  name: 'DemoLifeWeeks',
  data() {
    return {
      isDemoActive: false,
      hasStartedDemo: false,
      birthdate: null,
      years: [],
      hoursDecimalPlaces: 6,
      hoursTimer: null,
      currentHours: '0',
      lastUpdate: null,
      zoomedWeek: null,
      hoveredWeek: null,
      showSettingsDrawer: false,
      showYearGaps: false,
      compactView: true,
      showWeeklyTodos: true,
      currentWeekTodos: [],
      todoInput: '',
      showHoursUntilDeath: true,
      showQuotes: true,
      quotePosition: 'top',
      editingSummary: false,
      currentSummary: '',
      goalInput: '',
      weekSummaries: {},
      weekGoals: {},
      dailyHabits: [],
      newHabitName: '',
      newHabitDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      },
      weeksUntil80: 0,
      randomQuote: "Waste no more time arguing what a good man should be. Be One. – Marcus Aurelius",
      lifeStages: [
        { name: 'Early Years', startAge: 0, endAge: 5.999, color: '#ffebee' },
        { name: 'School Years', startAge: 6, endAge: 17.999, color: '#e8f5e9' },
        { name: 'Young Adult', startAge: 18, endAge: 29.999, color: '#e3f2fd' },
        { name: 'Adulthood', startAge: 30, endAge: 59.999, color: '#f3e5f5' },
        { name: 'Senior Years', startAge: 60, endAge: 80, color: '#fbe9e7' },
      ]
    }
  },
  computed: {
    hoursUntilDeath() {
      return this.currentHours;
    },
    filteredDailyHabits() {
      return this.sortedDailyHabits.filter(habit => this.isHabitActiveToday(habit));
    },
    sortedDailyHabits() {
      return [...this.dailyHabits].sort((a, b) => a.name.localeCompare(b.name));
    },
    habitCompletionPercentage() {
      const activeHabits = this.filteredDailyHabits;
      if (activeHabits.length === 0) return 0;
      
      const completedHabits = activeHabits.filter(habit => habit.completed);
      return Math.round((completedHabits.length / activeHabits.length) * 100);
    },
    isZoomedViewOpen() {
      return !!this.zoomedWeek;
    },
    currentWeekTodoProgress() {
      if (this.currentWeekTodos.length === 0) {
        return 0;
      }
      const completedTodos = this.currentWeekTodos.filter(todo => todo.done).length;
      return (completedTodos / this.currentWeekTodos.length) * 100;
    }
  },
  methods: {
    startDemo() {
      this.isDemoActive = true;
    },
    getDayName(index) {
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      return days[index];
    },
    calculateHours() {
      if (!this.birthdate) return '0';
      
      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);
      const lifeExpectancy = 80;
      
      const deathDate = new Date(birthDate);
      deathDate.setFullYear(birthDate.getFullYear() + lifeExpectancy);
      
      const diffTime = Math.abs(deathDate - currentDate);
      const diffHours = diffTime / (1000 * 60 * 60);
      
      return diffHours.toFixed(this.hoursDecimalPlaces);
    },

    startHoursTimer() {
      // Initial calculation
      this.currentHours = this.calculateHours();
      this.lastUpdate = Date.now();

      // Update every 50ms for smooth animation
      this.hoursTimer = setInterval(() => {
        // Just calculate and update the new value directly
        this.currentHours = this.calculateHours();
      }, 50);
    },

    stopHoursTimer() {
      if (this.hoursTimer) {
        clearInterval(this.hoursTimer);
        this.hoursTimer = null;
      }
    },

    isHabitActiveToday(habit) {
      if (!habit.days) return true;
      const today = new Date();
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const dayName = days[today.getDay()].toLowerCase();
      return habit.days[dayName] === true;
    },

    addHabit() {
      if (this.newHabitName.trim()) {
        const newHabit = {
          id: Date.now(),
          name: this.newHabitName.trim(),
          days: { ...this.newHabitDays },
          completed: false
        };
        
        this.dailyHabits.push(newHabit);
        this.newHabitName = '';
        this.resetHabitDays();
      }
    },

    resetHabitDays() {
      this.newHabitDays = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      };
    },

    deleteHabit(habit) {
      const index = this.dailyHabits.findIndex(h => h.id === habit.id);
      if (index !== -1) {
        this.dailyHabits.splice(index, 1);
      }
    },

    updateHabitStatus(habit) {
      const index = this.dailyHabits.findIndex(h => h.id === habit.id);
      if (index !== -1) {
        this.dailyHabits[index].completed = habit.completed;
      }
    },

    getDayLabel(day) {
      return day.charAt(0).toUpperCase();
    },

    toggleHabitDay(habit, day) {
      const updatedHabit = {...habit};
      updatedHabit.days = {...habit.days};
      updatedHabit.days[day] = !updatedHabit.days[day];
      
      const index = this.dailyHabits.findIndex(h => h.id === habit.id);
      if (index !== -1) {
        this.$set(this.dailyHabits, index, updatedHabit);
      }
    },
    deleteTodo(todo) {
      const index = this.currentWeekTodos.findIndex(t => t.id === todo.id);
      if (index !== -1) {
        this.currentWeekTodos.splice(index, 1);
      }
    },
    addTodo() {
      if (this.todoInput.trim() !== '') {
        const currentWeek = this.getCurrentWeek();
        if (currentWeek) {
          const newTodo = {
            id: Date.now(), // Simple unique ID for demo
            weekNumber: currentWeek.number,
            text: this.todoInput,
            done: false,
          };
          
          this.currentWeekTodos.push(newTodo);
          this.todoInput = '';
        }
      }
    },

    updateTodoStatus(todo) {
      const index = this.currentWeekTodos.findIndex(t => t.id === todo.id);
      if (index !== -1) {
        this.currentWeekTodos[index].done = todo.done;
      }
    },

    getCurrentWeek() {
      if (!this.birthdate) return null;
        
      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);
      
      currentDate.setHours(0, 0, 0, 0);
      birthDate.setHours(0, 0, 0, 0);
      
      const diffTime = currentDate - birthDate;
      const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));
      return this.getWeekByNumber(diffWeeks + 1);
    },

    getWeekByNumber(weekNumber) {
      const yearIndex = Math.floor((weekNumber - 1) / 52);
      const weekIndex = (weekNumber - 1) % 52;
      return this.years[yearIndex]?.[weekIndex];
    },

    initializeDemo() {
      if (!this.birthdate) return;
      this.hasStartedDemo = true;
      this.generateWeeks();
      this.startHoursTimer(); // Add this line
    },

    generateWeeks() {
      if (!this.birthdate) return;

      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);
      
      currentDate.setHours(0, 0, 0, 0);
      birthDate.setHours(0, 0, 0, 0);
      
      const diffTime = currentDate - birthDate;
      const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));

      const years = [];
      let yearWeeks = [];

      for (let i = 0; i < 4174; i++) {
        const startDate = new Date(birthDate);
        startDate.setDate(startDate.getDate() + i * 7);
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);

        const week = {
          number: i + 1,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          age: i / 52,
          isPast: i < diffWeeks,
          isCurrent: i === diffWeeks,
        };

        yearWeeks.push(week);

        if (yearWeeks.length === 52 || i === 4173) {
          years.push(yearWeeks);
          yearWeeks = [];
        }
      }

      this.years = years;
    },

    formatAge(age) {
      return age.toFixed(2);
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },

    formatMonthYear(week) {
      const date = new Date(week.startDate);
      const options = { month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },

    getLifeStageColor(age) {
      const lifeStage = this.lifeStages.find(stage => 
        age >= stage.startAge && age <= stage.endAge
      );
      return lifeStage ? `life-stage-${lifeStage.name.toLowerCase().replace(' ', '-')}` : '';
    },

    zoomWeek(week) {
      this.zoomedWeek = week;
      this.weeksUntil80 = 4174 - week.number;
      this.currentSummary = this.weekSummaries[week.number] || '';
    },

    closeZoomedView() {
      this.zoomedWeek = null;
      this.editingSummary = false;
    },

    showOverlay(week, event) {
      this.hoveredWeek = { week, event };
      this.$nextTick(() => {
        const overlay = this.$refs.overlay;
        if (overlay) {
          const rect = event.target.getBoundingClientRect();
          overlay.style.top = `${rect.bottom + 10}px`;
          overlay.style.left = `${rect.left}px`;
        }
      });
    },

    startEditingSummary() {
      this.editingSummary = true;
      this.currentSummary = this.weekSummaries[this.zoomedWeek.number] || '';
    },

    saveSummary() {
      if (this.zoomedWeek) {
        this.weekSummaries[this.zoomedWeek.number] = this.currentSummary;
      }
      this.editingSummary = false;
    },

    saveGoal() {
      if (!this.goalInput.trim() || !this.zoomedWeek) return;
      
      if (!this.weekGoals[this.zoomedWeek.number]) {
        this.weekGoals[this.zoomedWeek.number] = [];
      }
      
      this.weekGoals[this.zoomedWeek.number].push(this.goalInput);
      this.goalInput = '';
    },

    deleteMilestone(index) {
      if (this.zoomedWeek && this.weekGoals[this.zoomedWeek.number]) {
        this.weekGoals[this.zoomedWeek.number].splice(index, 1);
      }
    },

    hasMilestone(weekNumber) {
      return this.weekGoals[weekNumber]?.length > 0;
    },

    goToPreviousWeek() {
      if (this.zoomedWeek && this.zoomedWeek.number > 1) {
        const weekIndex = this.zoomedWeek.number - 2;
        const yearIndex = Math.floor(weekIndex / 52);
        const weekInYearIndex = weekIndex % 52;
        this.zoomWeek(this.years[yearIndex][weekInYearIndex]);
      }
    },

    goToNextWeek() {
      if (this.zoomedWeek && this.zoomedWeek.number < 4174) {
        const weekIndex = this.zoomedWeek.number;
        const yearIndex = Math.floor(weekIndex / 52);
        const weekInYearIndex = weekIndex % 52;
        this.zoomWeek(this.years[yearIndex][weekInYearIndex]);
      }
    }
  }
}
</script>


<style scoped>
.demo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-button {
  margin: 50px auto;
}

.demo-notice {
  color: white;
  font-size: 0.9rem;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
}

.demo-setup {
  text-align: center;
  padding: 40px;
}

:deep(.v-dialog .v-card) {
  padding: 0 !important;
}

.demo-input {
  max-width: 300px;
  margin: 20px auto;
}

.weekly-todos {
  top: 40px;
}

.daily-habits {
  top: 40px;
}


</style>
