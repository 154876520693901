<template>
  <v-dialog :model-value="show" @update:model-value="$emit('update:show', $event)" max-width="90vw">
    <v-card>
      <v-card-title class="text-h5 pb-8">
        Weekly Reflection for Week {{ previousWeekNumber }}
        <div class="text-subtitle-1 text-secondary">
          {{ previousWeekDates }}
        </div>
      </v-card-title>

      
      <v-card-text>
        <div class="questions-container">
          <div v-for="(question, index) in questions" :key="index" class="question-group">
            <div class="question-text">{{ question.text }}</div>
            <div class="rating-squares">
              <div 
                v-for="n in 11" 
                :key="n-1"
                class="square"
                :class="{
                  'selected': ratings[question.id] === n-1,
                  'filled': n-1 <= ratings[question.id]
                }"
                @click="setRating(question.id, n-1)"
              >
                {{ n-1 }}
              </div>
            </div>
            <div class="rating-labels">
              <span>{{ question.minLabel }}</span>
              <span>{{ question.maxLabel }}</span>
            </div>
          </div>
        </div>

        <!-- Add Open-Ended Reflection Questions -->
        <div class="reflection-container">
          <div v-for="(reflection, index) in reflections" :key="index" class="reflection-group">
            <div class="reflection-text">{{ reflection.text }}</div>
            <v-textarea 
              v-model="reflectionAnswers[reflection.id]" 
              rows="3"
              outlined
              class="mt-4"
            ></v-textarea>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveReflection">Save Reflection</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { auth, db } from '../firebaseInit';
import { addDoc, collection } from 'firebase/firestore';

export default {
  name: 'WeeklyReflection',
  props: {
    show: Boolean,
    weekNumber: {
      type: Number,
      required: true, // Ensure it's always passed from the parent
    },
    birthdate: {
      type: String,
      required: true, // Ensure birthdate is passed as a string
    },
  },
  emits: ['update:show'],
  
  data() {
    return {
      questions: [
        { id: 'happiness', text: 'How happy did you feel this week?', minLabel: 'Completely unhappy', maxLabel: 'Extremely happy' },
        { id: 'health', text: 'How well did you take care of your physical health this week?', minLabel: 'Completely neglected', maxLabel: 'Excellent care' },
        { id: 'connections', text: 'How connected did you feel to the people around you?', minLabel: 'Totally isolated', maxLabel: 'Deeply connected' },
        { id: 'alignment', text: 'How much did you live in alignment with your values this week?', minLabel: 'Not at all', maxLabel: 'Completely aligned' },
        { id: 'satisfaction', text: 'How satisfied are you with how you spent your time this week?', minLabel: 'Completely unsatisfied', maxLabel: 'Extremely satisfied' }
      ],
      reflections: [
        { id: 'wins', text: 'What was your biggest win this week?' },
        { id: 'hardships', text: 'What challenges or hardships did you face, and how did you handle them?' },
        { id: 'lessons', text: 'What is one thing you learned about yourself this week?' },
        { id: 'improvement', text: 'What is one thing you wish you had done differently this week?' },
        { id: 'nextWeek', text: 'What are you most looking forward to next week?' }
      ],
      ratings: {},
      reflectionAnswers: {}
    };
  },

  computed: {
    previousWeekNumber() {
      return this.weekNumber - 1;
    },
    previousWeekDates() {
      if (!this.previousWeekNumber || !this.birthdate) return null;

      // Parse the birthdate
      const birthDate = new Date(this.birthdate);

      // Calculate the start and end dates of the previous week
      const startDate = new Date(birthDate);
      startDate.setDate(birthDate.getDate() + (this.previousWeekNumber - 1) * 7);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      // Format dates
      const options = { month: 'short', day: 'numeric' };
      return `${startDate.toLocaleDateString(undefined, options)} - ${endDate.toLocaleDateString(undefined, options)}`;
    },
  },

  methods: {
    setRating(questionId, value) {
      this.ratings[questionId] = value;
    },

    async saveReflection() {
      if (!this.weekNumber) {
        console.error('Week number is not defined');
        return;
      }
      try {
        const userId = auth.currentUser.uid;
        await addDoc(collection(db, `users/${userId}/weeklyReflections`), {
          weekNumber: this.previousWeekNumber,
          timestamp: new Date(),
          ratings: this.ratings,
          reflections: this.reflectionAnswers,
        });

        // Reset answers after saving
        this.ratings = {};
        this.reflectionAnswers = {};
        this.$emit('update:show', false);
      } catch (error) {
        console.error('Error saving reflection:', error);
      }
    },
  }
};
</script>

<style scoped>
.questions-container {
  max-width: 480px;
  margin: 0 auto;
}

.question-group {
  margin-bottom: 2rem;
}

.question-text {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #333;
}

.rating-squares {
  display: flex;
  gap: 4px;
  margin-bottom: 0.5rem;
}

.square {
  width: 40px;
  height: 40px;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  color: #666;
}

.square:hover {
  background-color: #f5f5f5;
  border-color: #999;
}

.square.selected {
  background-color: #78edff;
  border-color: #78edff;
  color: white;
}

.square.filled {
  background-color: #78edff;
  border-color: #78edff;
  color: white;
}

.rating-labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #666;
  margin-top: 4px;
}

.v-card-title {
  border-bottom: 1px solid #eee;
}

.reflection-container {
  max-width: 480px;
  margin: 0 auto;
}


.reflection-text {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #333;
}

.text-secondary {
  font-size: 0.9rem;
  color: #888;
}

</style>